<template>
  <mobile-screen
    :header="true"
    screen-class="occupancy-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_occupancy-help-online-finder'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy.finder.finder") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <!-- Levels selection -->
    <ul
      v-if="finderLevels && finderLevels.length"
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse['levels'] ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("occupancy.finder.levels") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse['levels']
                ? (collapse['levels'] = false)
                : (collapse['levels'] = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section :class="collapse['levels'] ? 'collapsed' : 'expanded five'">
        <li
          v-for="level in finderLevels"
          :key="level.id"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <div
            @click="selectLevel(level)"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer"
                >{{ level.full_path }}
              </label>
            </div>
            <span
              v-if="level.id === finderSelectedLevelId"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </li>
      </section>
    </ul>
    <template v-slot:footer> </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import { getLang, getSubdomain } from "@/services/http-service";
import qs from "qs";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "OccupancyFinder",
  mixins: [helpOnlineMixin, mapMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      collapse: {},
      helpSlug: "occupancy-finder"
    };
  },
  computed: {
    ...mapState("search", ["searchQuery"]),
    ...mapState("occupancy", ["finderLevels", "finderSelectedLevelId"])
  },
  created() {
    this.getFinderLevels();
  },
  methods: {
    ...mapActions("occupancy", ["getFinderLevels"]),
    selectLevel(level) {
      this.$store.commit("occupancy/setFinderSelectedLevelId", level.id, {
        root: true
      });
      if (level.map_id) {
        const mapUrl = qs.stringify({
          access_token: localStorage.getItem("atApp"),
          language: getLang(),
          mode: "occupancy"
        });

        const subdomain = getSubdomain();
        let subdomainUrl = "";
        if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

        const url = `${process.env.VUE_APP_5DMAPS_URL}/view/${
          level.map_id
        }?api=${this.getMapApi()}&${mapUrl}${subdomainUrl}`;

        this.$store.commit("occupancy/setMapUrl", url, {
          root: true
        });
        this.$router.push({ name: "r_occupancy-finder-map" });
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("occupancy/setFinderSelectedLevelId", null, {
      root: true
    });
  }
};
</script>
